import { Language } from '@/constants'

export const LANG_COMMON = {
	[Language.ru]: {
		submit: 'Применить',
		cancel: 'Отменить',
		save: 'Сохранить',
		add: 'Добавить',
		set: 'Установить',
		delete: 'Удалить',
		create: 'Создать',
		reset: 'Сбросить',
		search: 'Поиск',
		select: 'Выбрать',
		yes: 'Да',
		no: 'Нет',
		true: 'Да',
		false: 'Нет',
		exit: 'Выход',
		cls: 'Класс',
		classes: 'Классы',
		attribute: 'Атрибут',
		literal: 'Литерал',
		literals: 'Свойства-литералы',
		reference: 'Ссылка',
		references: 'Свойства-ссылки',
		aboveCls: 'Надкласс',
		filter: 'Фильтр',
		value: 'Значение',
		error: 'Ошибка',
		aboutTheApp: 'О приложении',
		timezone: 'Часовой пояс',
		settings: 'Настройки',
		export: 'Экспорт',
		import: 'Импорт',
		download: 'Скачать',
		upload: 'Загрузить',
		metrics: 'Метрика',
		owlExport: 'Экспорт OWL',
		owlImport: 'Импорт OWL',
		owlImportExport: 'Импорт/экспорт OWL',
		nTriplesExport: 'Экспорт NTriples',
		nTriplesImport: 'Импорт NTriples',
		nTriplesImportExport: 'Импорт/экспорт NTriples',
		browse: 'Обзор',
		pageSize: 'Размер страницы',
		back: 'Назад',
		attributes: 'Атрибуты',
		close: 'Закрыть',
		identifier: 'Идентификатор',
		label: 'Наименование',
		comment: 'Описание',
		autogen: 'Создать автоматически',
	},
	[Language.en]: {
		submit: 'Submit',
		cancel: 'Cancel',
		save: 'Save',
		add: 'Add',
		set: 'Set',
		delete: 'Delete',
		create: 'Create',
		reset: 'Reset',
		search: 'Search',
		select: 'Select',
		aboveCls: 'Overclass',
		yes: 'Yes',
		no: 'No',
		true: 'True',
		false: 'False',
		exit: 'Exit',
		datatypeInteger: 'Integer',
		cls: 'Class',
		classes: 'Classes',
		attribute: 'Attribute',
		literal: 'Literal',
		literals: 'Literals',
		reference: 'Reference',
		references: 'References',
		filter: 'Filter',
		value: 'Value',
		error: 'Error',
		aboutTheApp: 'About the app',
		timezone: 'Time zone',
		settings: 'Settings',
		export: 'Export',
		import: 'Import',
		download: 'Download',
		upload: 'Upload',
		metrics: 'Metrics',
		owlExport: 'OWL export',
		owlImport: 'OWL import',
		owlImportExport: 'OWL import/export',
		nTriplesExport: 'NTriples export',
		nTriplesImport: 'NTriples import',
		nTriplesImportExport: 'NTriples import/export',
		browse: 'Browse',
		pageSize: 'Page size',
		back: 'Back',
		attributes: 'Attributes',
		close: 'Close',
		identifier: 'Identifier',
		label: 'Label',
		comment: 'Comment',
		autogen: 'Auto generate',
	},
}
